import React, { useState, useEffect } from "react"
import * as S from "./blogs-page.styles"
import { Container } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import FeaturedBlog from "../../components/featured-blog/featured-blog.component"
import BlogsPagination from "../../components/blogs-pagination/blogs-pagination.component"
import BlogsFilter from "../../components/blogs-filter/blogs-filter.component"
import { gsap } from "gsap"
import SplitType from "split-type"

const BlogsPage = ({ featuredBlog }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          categories {
            nodes {
              name
              slug
            }
          }
          uri
          date(formatString: "MMMM DD, YYYY")
          excerpt
          slug
          featuredImage {
            node {
              altText
              sourceUrl
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
            }
          }
          postBuilder {
            layouts {
              ... on WpPost_Postbuilder_Layouts_MiscContentPost {
                content
                fieldGroupName
              }
            }
          }
        }
      }
    }
  `)
  const blogs = staticQuery.allWpPost.nodes
  const blogsWithOutFeatured = blogs.filter(({ id }) => id !== featuredBlog.id)
  const [blogsToShow, setBlogsToShow] = useState(blogsWithOutFeatured)

  useEffect(() => {
    const tl = gsap.timeline()
    const text = new SplitType(".animated-title", { types: "lines, words" })

    gsap.to(".title", {
      autoAlpha: 1,
      duration: 0.2,
    })

    tl.from(".line .word", 1.2, {
      y: -200,
      ease: "power4.out",
      delay: 0.5,
      opacity: 0,
      stagger: {
        amount: 0.4,
      },
    })
  }, [])
  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        <FeaturedBlog
          title={featuredBlog.title}
          date={featuredBlog.date}
          featuredImage={featuredBlog.postBuilder.firstImage}
          description={featuredBlog.excerpt}
          uri={`/blog/${featuredBlog.slug}`}
        />
        <BlogsFilter
          setBlogsToShow={setBlogsToShow}
          allBlogs={blogs}
          blogs={blogsWithOutFeatured}
        />
        <BlogsPagination blogs={blogsToShow} />
      </Container>
    </S.Wrapper>
  )
}

export default BlogsPage
