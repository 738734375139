import { Grid } from "@mui/material"
import React, { useState, useEffect, useRef } from "react"
import * as S from "./testimonial-card.styles"
import MapMarker from "../../assets/map-marker.svg"
import LoadableModalVideo from "../loadable-modal-video/loadable-modal-video"
import { isBrowser } from "../../utils/utils"
import { ScrollTrigger, gsap } from "gsap/all"

const TestimonialCard = ({
  city,
  name,
  title,
  tags,
  description,
  features,
  videoId,
  videoImage,
  first,
  last,
}) => {
  const [isOpenVideo, setIsOpenVideo] = useState(false)
  const cardRef = useRef(null)
  useEffect(() => {
    let st = null
    if (isBrowser) {
      gsap.registerPlugin(ScrollTrigger)
      st = ScrollTrigger.create({
        trigger: cardRef.current,
        start: "-200 300",
        end: "100% 500",
        // markers: true,
        scrub: 1,
        onEnter: () => {
          if (!first && cardRef.current) {
            cardRef.current.classList.add("active")
          }
        },
        onEnterBack: () => {
          if (!last && cardRef.current) {
            cardRef.current.classList.add("active")
          }
        },
        onLeave: () => {
          if (!last && cardRef.current) {
            cardRef.current.classList.remove("active")
          }
        },
        onLeaveBack: () => {
          if (!first && cardRef.current) {
            cardRef.current.classList.remove("active")
          }
        },
      })
    }
    return () => {
      if (st) {
        st.kill()
      }
    }
  }, [])
  useEffect(() => {
    if (isOpenVideo && isBrowser) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isOpenVideo])
  return (
    <S.Wrapper ref={cardRef} className={first && "active"}>
      <LoadableModalVideo
        channel={"youtube"}
        isOpen={isOpenVideo}
        videoId={videoId}
        //   url={videoFile?.sourceUrl}
        autoplay={1}
        onClose={() => setIsOpenVideo(false)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <S.LeftWrapper>
            <S.ImageWrapper>
              <S.VideoImage img={videoImage} alt={name} />
              <S.PlayWrapper>
                <S.PlayButton onClick={() => setIsOpenVideo(true)} />
              </S.PlayWrapper>
            </S.ImageWrapper>
          </S.LeftWrapper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <S.Name>{name}</S.Name>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
          <S.FeaturesWrapper>
            <Grid container spacing={{ xs: 4, md: 6 }}>
              {features?.map(({ title, description }, index) => (
                <Grid item xs key={`feature-${index}`}>
                  <S.FeatureTitle>{title}</S.FeatureTitle>
                  <S.FeatureDescription>{description}</S.FeatureDescription>
                </Grid>
              ))}
            </Grid>
          </S.FeaturesWrapper>
          <S.City>
            <MapMarker className="map-marker" />
            {city}
          </S.City>
          <S.TagsWrapper>
            {tags?.map(({ tag }, index) => (
              <S.Tag key={`tag-${index}`} className="tag">
                {tag}
              </S.Tag>
            ))}
          </S.TagsWrapper>
        </Grid>
      </Grid>
    </S.Wrapper>
  )
}

export default TestimonialCard
