import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  // ${({ theme }) => theme.breakpoints.up("md")} {
  //   padding-top: 10vh;
  // }
  padding-top: 0;
`

export const Title = styled.h1`
  .line {
    overflow: hidden;
  }

  visibility: hidden;

  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(30)};
  line-height: 74px;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(20)}

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(35)};
    line-height: 96px;
    font-weight: 500;
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(20)}
  }
`
