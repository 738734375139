import { Grid } from "@mui/material"
import React, { useState } from "react"
import RatingCard from "../../components/rating-card/rating-card.component"
import * as S from "./testimonial-rating.styles"

const TestimonialRating = ({ title, logos, ratings }) => {
  const [loadedItems, setLoadedItems] = useState(9)

  const loadMore = () => {
    setLoadedItems(loadedItems + 3)
  }
  return (
    <S.Section contained maxWidth="xl">
      <S.TopWrapper>
        <S.Title variant="h2">{title}</S.Title>
        <S.LogosWrapper>
          {logos.map(({ image }, index) => (
            <S.Logo key={`logo-${index}`} img={image} alt={image.altText} />
          ))}
        </S.LogosWrapper>
      </S.TopWrapper>
      <S.CommentsWrapper>
        <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 4, sm: 6 }}>
          {ratings?.map((item, index) => (
            <React.Fragment key={`rating-${index}`}>
              {index < loadedItems && (
                <Grid item xs={12} sm={6} md={4}>
                  <RatingCard {...item} />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </S.CommentsWrapper>
      {loadedItems < ratings.length && (
        <S.LoadMoreWrapper>
          <S.LoadMoreButton className="darkBorder" onClick={loadMore}>
            Load more Reviews
          </S.LoadMoreButton>
        </S.LoadMoreWrapper>
      )}
    </S.Section>
  )
}

export default TestimonialRating
