import { Box, Grid } from "@mui/material"
import React from "react"
import * as S from "./achievement-cards.styles"
import parse from "html-react-parser"

const AchievementCards = ({
  name,
  photo,
  description,
  iconTitle,
  phrase,
  achievements,
  ...otherProps
}) => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 3 }}
      columnSpacing={{ xs: 2 }}
      marginBottom={"1.5rem"}
      {...otherProps}
    >
      {achievements.map(({ icon, title, description }, index) => (
        <Grid item xs={12} sm={6} key={`achievement-${index}`}>
          <S.AchievementCard>
            <S.AchievementIcon img={icon} alt={title} />
            <Box>
              <S.AchievementTitle>{title}</S.AchievementTitle>
              <S.AchievementDescription>{description}</S.AchievementDescription>
            </Box>
          </S.AchievementCard>
        </Grid>
      ))}
      <Grid item xs={12}>
        <S.AuthorCard>
          <Grid container spacing={{ xs: 3, md: 8 }}>
            <Grid item xs={12} sm={7}>
              <S.LeftWrapper>
                <S.IconTitle img={iconTitle} alt={`${name} icon`} />
                <S.AuthorPhrase>{parse(phrase)}</S.AuthorPhrase>
              </S.LeftWrapper>
            </Grid>
            <Grid item xs={12} sm={5}>
              <S.AvatarCard>
                <S.AvatarImage img={photo} alt={name} />
                <S.AvatarName>{name}</S.AvatarName>
                <S.AvatarDescription>{parse(description)}</S.AvatarDescription>
              </S.AvatarCard>
            </Grid>
          </Grid>
        </S.AuthorCard>
      </Grid>
    </Grid>
  )
}

export default AchievementCards
