import React from "react"
import MiscContent from "../layouts/MiscContent"
import AppInfo from "../layouts/AppInfo/app-info.component"
import CallToAction from "../layouts/CallToAction"
import HomeHero from "../layouts/HomeHero"
import ExperienceSlider from "../layouts/ExperienceSlider/experience-slider.component"
import ImagesCollage from "../layouts/ImagesCollage"
import ImageSlider from "../layouts/ImageSlider"
import InfoVideo from "../layouts/InfoVideo"
import BlogsPage from "../layouts/BlogsPage"
import PricingPlans from "../layouts/PricingPlans"
import CustomHero from "../layouts/CustomHero"
import BuiltForInvestors from "../layouts/BuiltForInvestors"
import TransparencyTracking from "../layouts/TransparencyTracking"
import NextToFooter from "../layouts/NextToFooter"
import WhatSetsApart from "../layouts/WhatSetsApart"
import BackedBy from "../layouts/BackedBy"
import SecondaryHero from "../layouts/SecondaryHero"
import CostsAndFees from "../layouts/CostsAndFees"
import AboutCalculator from "../layouts/AboutCalculator"
import SubmitDeal from "../layouts/SubmitDeal"
import HowItWorks from "../layouts/HowItWorks"
import InvestorsSlider from "../layouts/InvestorsSlider"
import DemoHero from "../layouts/DemoHero"
import ShortDemoHero from "../layouts/ShortDemoHero"
import ExperiencesGrid from "../layouts/ExperiencesGrid"
import TrustedBySlider from "../layouts/TrustedBySlider"
import OurServicesHero from "../layouts/OurServicesHero"
import FaqHero from "../layouts/FaqHero"
import FaqContent from "../layouts/FaqContent"
import PostProperty from "../layouts/PostProperty"
import TestimonialHero from "../layouts/TestimonialHero"
import OurAchievements from "../layouts/OurAchievements"
import TestimonialPartners from "../layouts/TestimonialPartners"
import TestimonialRating from "../layouts/TestimonialRating"
import StatesLicensed from "../layouts/StatesLicensed"
import DemoThankYou from "../layouts/DemoThankYou"
import TestimonialBanner from "../layouts/TestimonialBanner"
import Map from "../layouts/Map"

export const getPageLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "AppInfo":
      return <AppInfo {...layout} />
    case "CallToAction":
      return <CallToAction {...layout} />
    case "HomeHero":
      return <HomeHero {...layout} />
    case "ExperienceSlider":
      return <ExperienceSlider {...layout} />
    case "ImagesCollage":
      return <ImagesCollage {...layout} />
    case "ImageSlider":
      return <ImageSlider {...layout} />
    case "InfoVideo":
      return <InfoVideo {...layout} />
    case "BlogsPage":
      return <BlogsPage {...layout} />
    case "PricingPlans":
      return <PricingPlans {...layout} />
    case "CustomHero":
      return <CustomHero {...layout} />
    case "BuiltForInvestors":
      return <BuiltForInvestors {...layout} />
    case "TransparencyTracking":
      return <TransparencyTracking {...layout} />
    case "NextToFooter":
      return <NextToFooter {...layout} />
    case "WhatSetsApart":
      return <WhatSetsApart {...layout} />
    case "BackedBy":
      return <BackedBy {...layout} />
    case "SecondaryHero":
      return <SecondaryHero {...layout} />
    case "CostsAndFees":
      return <CostsAndFees {...layout} />
    case "AboutCalculator":
      return <AboutCalculator {...layout} />
    case "SubmitDeal":
      return <SubmitDeal {...layout} />
    case "HowItWorks":
      return <HowItWorks {...layout} />
    case "InvestorsSlider":
      return <InvestorsSlider {...layout} />
    case "DemoHero":
      return <DemoHero {...layout} />
      case "ShortDemoHero":
        return <ShortDemoHero {...layout} />
    case "ExperiencesGrid":
      return <ExperiencesGrid {...layout} />
    case "TrustedBySlider":
      return <TrustedBySlider {...layout} />
    case "OurServicesHero":
      return <OurServicesHero {...layout} />
    case "FaqHero":
      return <FaqHero {...layout} />
    case "FaqContent":
      return <FaqContent {...layout} />
    case "PostProperty":
      return <PostProperty {...layout} />
    case "TestimonialHero":
      return <TestimonialHero {...layout} />
    case "OurAchievements":
      return <OurAchievements {...layout} />
    case "TestimonialPartners":
      return <TestimonialPartners {...layout} />
    case "TestimonialRating":
      return <TestimonialRating {...layout} />
    case "StatesLicensed":
      return <StatesLicensed {...layout} />
    case "DemoThankYou":
      return <DemoThankYou {...layout} />
    case "TestimonialBanner":
      return <TestimonialBanner {...layout} />
    case "Map":
      return <Map {...layout} />
  }
}
