import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  padding: 0;
  overflow: hidden;
  margin-top: -${({ theme }) => theme.navHeight}px;
  //padding-top: calc(10vh + ${({ theme }) => theme.navHeight}px);
  padding-top: ${({ theme }) => theme.navHeight}px;
`
