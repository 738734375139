import PaginationItem from "@mui/material/PaginationItem"
import styled from "@emotion/styled"

export const CustomPaginationItem = styled(PaginationItem)`
  &.cNMtRq {
    border-radius: unset;

    &:hover {
      background-color: unset;
    }
  }

  &.Mui-selected {
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.primary.main};
    max-width: 16px;
    color: white;
  }
`
