import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Typography from "@mui/material/Typography"
import CustomButton from "../../components/custom-button/custom-button.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import Container from "@mui/material/Container"
import Logo from "../../assets/logo-card.svg"
import Polygon from "../../assets/polygon.svg"

export const Wrapper = styled(SectionWrapper)`
  margin-top: -${({ theme }) => theme.navHeight}px;
  padding-top: ${({ theme }) => theme.navHeight}px;

  overflow: hidden;
  padding-bottom: 0;
`

export const CustomContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 2rem;
  }
`

export const Title = styled.h1`
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
  .line {
    overflow: hidden;
  }
  font-size: ${({ theme }) => theme.typography.pxToRem(46)};
  line-height: ${({ theme }) => theme.typography.pxToRem(60)};
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(62)};
    line-height: ${({ theme }) => theme.typography.pxToRem(74)};
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(72)};
    line-height: ${({ theme }) => theme.typography.pxToRem(88)};
  }
`

export const Paragraph = styled(Typography)`
  visibility: hidden;
  font-style: normal;
  font-weight: 400;
  padding-top: 1.5rem;
  font-size: 1rem;
  line-height: 26px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(21)};
    line-height: 28px;
    padding-top: 1.25rem;
    max-width: 625px;
  }
`

export const TextWrapper = styled.div`
  z-index: 10;
  position: relative;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 10vh;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 2.5rem;
  }
  padding-top: 0.5rem;
`

export const StyledBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 460px;
  max-height: 860px;
  margin-left: 4rem;
  margin-right: 4rem;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 375px;
    max-height: 701px;
    margin-left: 5rem;
    margin-right: 2rem;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 314px;
    max-height: 587px;
    margin-left: 0;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`

export const Button = styled(CustomButton)`
  margin-top: 2.25rem;
  visibility: hidden;
`

export const ImageHero = styled(CustomImage)`
  z-index: 10;
  visibility: hidden;
  width: 100%;
  img {
    object-fit: contain !important;
    border-top-right-radius: 400px !important;
    border-top-left-radius: 400px !important;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
  }
`

export const HandImage = styled(CustomImage)`
  z-index: 10;
  visibility: hidden;
  position: absolute;
  width: 50%;
  height: 100%;
  top: 2%;
  right: -37%;
  img {
    object-fit: contain !important;
  }
`

export const Card = styled.div`
  visibility: hidden;
  position: absolute;
  top: 10%;
  left: -10%;
  z-index: 2;
  background: #ffffff;
  box-shadow: -4.43182px 5.90909px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media only screen and (max-width: 416px) {
    left: -10px;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    left: -25%;
    top: 20%;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    left: -45%;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    bottom: 32%;
    top: unset;
  }
`

export const CardPolygon = styled(Polygon)`
  position: absolute;
  z-index: 22;
  width: 23px;
  height: 26px;
  right: 26px;
  bottom: -23px;
`

export const CardText = styled(Typography)`
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
  font-size: 0.625rem;
  line-height: 13px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 0.875rem;
    line-height: 19px;
  }
`

export const CardIcon = styled(Logo)`
  width: 13px;
  height: auto;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 21px;
  }
`
