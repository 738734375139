import styled from "@emotion/styled"
import CustomLink from "../custom-link/custom-link.component"
import CustomImage from "../custom-image/custom-image.component"

export const Link = styled(CustomLink)`
  &:hover {
    h3 {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    div {
      .overlay {
        opacity: 1;
      }
    }
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(68)};
  }
`

export const Image = styled(CustomImage)`
  //height: 190px;
  margin-bottom: 1rem;
  img {
    //height: 190px;
    object-fit: cover !important;
    border-radius: 8px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 435px;
    margin-bottom: 2em;
    img {
      height: 435px;
      object-fit: cover !important;
      border-radius: 8px;
    }
  }
`

export const Date = styled.span`
  opacity: 0.8;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 22px;
  }
`

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-top: ${({ theme }) => theme.typography.pxToRem(7)};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: 27px;
  transition: all 0.3s ease-out 0s;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(29)};
    line-height: 34px;
    font-weight: 500;
  }
`

export const TextWrapper = styled.div`
  p {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 29px;
    margin-bottom: 0;
  }
`

export const ImageContainer = styled.div`
  display: block;
  position: relative;
`

export const ImageOverlay = styled.div`
  background-color: rgba(252, 74, 90, 0.8);
  border-radius: 10px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s;
`
