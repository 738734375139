import { Box, Grid } from "@mui/material"
import React, { useEffect } from "react"
import * as S from "./demo-thank-you.styles"
import parse from "html-react-parser"
import { isBrowser } from "../../utils/utils"

const DemoThankYou = ({ title, description }) => {
 
  return (
    <S.Section>
      <S.Wrapper>
        <Grid
          container
          spacing={{ xs: 3, md: 6 }}
          zIndex="2"
          position="relative"
        >
          <Grid item xs={12} md={12}>
            {title && <S.Title variant="h2">{title}</S.Title>}
            {description && <S.Description>{parse(description)}</S.Description>}
          </Grid>
         
        </Grid>
        <S.EmporaIcon />
      </S.Wrapper>
    </S.Section>
  )
}

export default DemoThankYou
