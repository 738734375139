import styled from "@emotion/styled"
import CustomImage from "../custom-image/custom-image.component"
import CustomLink from "../custom-link/custom-link.component"
import CustomButton from "../custom-button/custom-button.component"
import Typography from "@mui/material/Typography"
import CustomBgImage from "../custom-bg-image/custom-bg-image.component"
import { Grid } from "@mui/material"
import EmporaLogoBg from "../../assets/logo_blog.svg"

export const Wrapper = styled(CustomLink)`
  text-decoration: inherit;
  color: inherit;

  &:hover {
    cursor: pointer;

    h2 {
      color: ${({ theme }) => theme.palette.primary.main};
    }

    div {
      .overlay {
        opacity: 1;
      }
    }
  }
`

export const BgImage = styled(CustomBgImage)`
  min-height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.5;
  }
`

export const BlogImage = styled(CustomImage)`
  width: 100%;
  height: 100%;
  img {
    border-radius: 0 8px 8px 0;
    object-position: left;
    ${({ theme }) => theme.breakpoints.down("md")} {
      border-radius: 0 0 8px 8px;
    }
  }
`

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(46)};
  line-height: 55px;
  font-weight: 500;
  margin-top: ${({ theme }) => theme.typography.pxToRem(22)};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(14)};
  color: white;
  max-width: 850px;
  text-align: left;
  transition: all 0.3s ease-out 0s;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: ${({ theme }) => theme.typography.pxToRem(28)};
    line-height: 42px;
  }
`

export const Date = styled.span`
  position: relative;
  color: white;
  margin-bottom: 1.25rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 2.5rem;
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 21px;
  }
`

export const GridContainer = styled(Grid)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const GridItem = styled(Grid)`
  position: relative;
  overflow: hidden;
`

export const EmporaLogo = styled(EmporaLogoBg)`
  width: 80%;
  position: absolute;
  bottom: -45%;
  left: 45%;
  transform: translateY(10%);
  z-index: 2;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 60%;
    bottom: -83%;
    /* -webkit-transform: unset; */
    -moz-transform: unset;
    -ms-transform: unset;
    /* transform: unset; */
    left: 41%;
  }
`

export const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  z-index: 4;
  padding: 2rem;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: ${({ theme }) => theme.typography.pxToRem(88)}
      ${({ theme }) => theme.typography.pxToRem(100)};
  }
`

export const ImageContainer = styled.div`
  display: block;
  position: relative;

  //&:hover {
  //  .overlay {
  //    opacity: 1;
  //  }
  //}
`

export const ImageOverlay = styled.div`
  background-color: rgba(252, 74, 90, 0.8);
  border-radius: 10px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s;
`

export const Featured = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  text-align: left;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: white;
`

export const Button = styled(CustomButton)`
  max-width: 160px;
`
