import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { getPageLayout } from "../../utils/get-layout-utils"
import SEO from "../../components/seo/seo.component"

export const query = graphql`
  query PageQuery($uri: String!) {
    wpPage(uri: { eq: $uri }) {
      id
      isFrontPage
      title
      content
      uri
      slug
      parentId
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_AppInfo {
            ...AppInfo
          }
          ... on WpPage_Pagebuilder_Layouts_CallToAction {
            ...CallToAction
          }
          ... on WpPage_Pagebuilder_Layouts_HomeHero {
            ...HomeHero
          }
          ... on WpPage_Pagebuilder_Layouts_ExperienceSlider {
            ...ExperienceSlider
          }
          ... on WpPage_Pagebuilder_Layouts_ImagesCollage {
            ...ImagesCollage
          }
          ... on WpPage_Pagebuilder_Layouts_ImageSlider {
            ...ImageSlider
          }
          ... on WpPage_Pagebuilder_Layouts_InfoVideo {
            ...InfoVideo
          }
          ... on WpPage_Pagebuilder_Layouts_BlogsPage {
            ...BlogsPage
          }
          ... on WpPage_Pagebuilder_Layouts_PricingPlans {
            ...PricingPlans
          }
          ... on WpPage_Pagebuilder_Layouts_CustomHero {
            ...CustomHero
          }
          ... on WpPage_Pagebuilder_Layouts_BuiltForInvestors {
            ...BuiltForInvestors
          }
          ... on WpPage_Pagebuilder_Layouts_TransparencyTracking {
            ...TransparencyTracking
          }
          ... on WpPage_Pagebuilder_Layouts_NextToFooter {
            ...NextToFooter
          }
          ... on WpPage_Pagebuilder_Layouts_WhatSetsApart {
            ...WhatSetsApart
          }
          ... on WpPage_Pagebuilder_Layouts_BackedBy {
            ...BackedBy
          }
          ... on WpPage_Pagebuilder_Layouts_SecondaryHero {
            ...SecondaryHero
          }
          ... on WpPage_Pagebuilder_Layouts_CostsAndFees {
            ...CostsAndFees
          }
          ... on WpPage_Pagebuilder_Layouts_AboutCalculator {
            ...AboutCalculator
          }
          ... on WpPage_Pagebuilder_Layouts_SubmitDeal {
            ...SubmitDeal
          }
          ... on WpPage_Pagebuilder_Layouts_HowItWorks {
            ...HowItWorks
          }
          ... on WpPage_Pagebuilder_Layouts_InvestorsSlider {
            ...InvestorsSlider
          }
          ... on WpPage_Pagebuilder_Layouts_DemoHero {
            ...DemoHero
          }
          ... on WpPage_Pagebuilder_Layouts_ShortDemoHero {
            ...ShortDemoHero
          }
          ... on WpPage_Pagebuilder_Layouts_ExperiencesGrid {
            ...ExperiencesGrid
          }
          ... on WpPage_Pagebuilder_Layouts_TrustedBySlider {
            ...TrustedBySlider
          }
          ... on WpPage_Pagebuilder_Layouts_OurServicesHero {
            ...OurServicesHero
          }
          ... on WpPage_Pagebuilder_Layouts_FaqHero {
            ...FaqHero
          }
          ... on WpPage_Pagebuilder_Layouts_FaqContent {
            ...FaqContent
          }
          ... on WpPage_Pagebuilder_Layouts_PostProperty {
            ...PostProperty
          }
          ... on WpPage_Pagebuilder_Layouts_TestimonialHero {
            ...TestimonialHero
          }
          ... on WpPage_Pagebuilder_Layouts_OurAchievements {
            ...OurAchievements
          }
          ... on WpPage_Pagebuilder_Layouts_TestimonialPartners {
            ...TestimonialPartners
          }
          ... on WpPage_Pagebuilder_Layouts_TestimonialRating {
            ...TestimonialRating
          }
          ... on WpPage_Pagebuilder_Layouts_StatesLicensed {
            ...StatesLicensed
          }
          ... on WpPage_Pagebuilder_Layouts_DemoThankYou {
            ...DemoThankYou
          }
          ... on WpPage_Pagebuilder_Layouts_TestimonialBanner {
            ...TestimonialBanner
          }
          ... on WpPage_Pagebuilder_Layouts_Map {
            ...Map
          }
        }
        pageConfiguration {
          hideFooter
          hideHeaderItems
          transparentNavbar
          lightNavbarText
        }
      }
    }
  }
`

export const Head = ({ data }) => <SEO data={data?.wpPage?.seo} />

const PageTemplate = ({ data }) => {
  const { pageBuilder } = data.wpPage
  const layouts = pageBuilder.layouts || []
  return (
    <Layout {...pageBuilder.pageConfiguration}>
      {layouts.map(layout => getPageLayout(layout))}
    </Layout>
  )
}

export default PageTemplate
