import { Grid } from "@mui/material"
import React from "react"
import * as S from "./states-licensed.styles"
import parse from "html-react-parser"
//purple dots ohio, kentucky, indiana, florida, pennsylvania, Missouri  purpple dots
const dotsPosition = [
  {
    top: "28%",
    left: "51%",
  },
  {
    top: "27%",
    left: "60%",
  },
  {
    top: "36%",
    left: "56%",
  },
  {
    top: "66%",
    left: "70%",
  },
  {
    top: "23%",
    left: "72%",
  },
  // comment out georgia
 // { 
 //   top: "52%",
//left: "62%",
//  },
  {
    top: "35%",
    left: "34%",
  },
]

const StatusLicensed = ({ items }) => {
  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        <S.MapWrapper>
          <S.MapPoints />
          {dotsPosition.map((item, index) => {
            return (
              <S.MapDot {...item} key={`dots-${index}`}>
                <div />
                <div />
                <div />
              </S.MapDot>
            )
          })}
        </S.MapWrapper>
        <S.ItemsWrapper>
          <Grid container spacing={{ xs: 8, md: 10 }}>
            {items.map(({ title, description }, index) => (
              <Grid item xs={12} lg={4} key={`item-${index}`}>
                <S.ItemTitle>{title}</S.ItemTitle>
                <S.ItemDescription>{parse(description)}</S.ItemDescription>
              </Grid>
            ))}
          </Grid>
        </S.ItemsWrapper>
      </S.Wrapper>
    </S.Section>
  )
}

export default StatusLicensed
