import React from "react"
import * as S from "./featured-blog.styles"
import { Grid } from "@mui/material"
import parse from "html-react-parser"
import CustomButton from "../custom-button/custom-button.component"
import PlusCircle from "../../assets/pluss-circle.svg"
import Container from "@mui/material/Container"

const FeaturedBlog = ({ title, description, date, uri, featuredImage }) => {
  return (
    <S.Wrapper url={`${uri}` ?? "/"}>
      {/*<S.BgImage img={featuredImage}>*/}
      {/*  <S.TextContainer maxWidth={"lg"}>*/}
      {/*    <S.Featured>Featured</S.Featured>*/}
      {/*    <S.Title>{title}</S.Title>*/}
      {/*    /!*{parse(description)}*!/*/}
      {/*    <S.Date>{date}</S.Date>*/}
      {/*    <S.Button>Read More</S.Button>*/}
      {/*  </S.TextContainer>*/}
      {/*</S.BgImage>*/}
      <S.GridContainer container>
        <S.GridItem item md={6} xs={12}>
          <S.TextWrapper>
            <S.Featured>Featured</S.Featured>
            <S.Title>{title}</S.Title>
            <S.Date>{date}</S.Date>
            <S.Button className={"light"}>Read More</S.Button>
          </S.TextWrapper>
          <S.EmporaLogo />
        </S.GridItem>
        <Grid item md={6} xs={12}>
          <S.BlogImage img={featuredImage} />
        </Grid>
      </S.GridContainer>
    </S.Wrapper>
  )
}

export default FeaturedBlog
