import { Grid } from "@mui/material"
import React, { useEffect } from "react"
import * as S from "./testimonial-hero.styles"
import { gsap } from "gsap"
import SplitType from "split-type"
import { isBrowser } from "../../utils/utils"

const TestimonialHero = ({
  title,
  description,
  handImage,
  roundedImage,
  buttonLink,
}) => {
  useEffect(() => {
    if (isBrowser) {
      const tl = gsap.timeline()
      new SplitType(".animated-title", { types: "lines, words" })

      gsap.to(".hero-image-wrapper", {
        autoAlpha: 1,
        duration: 0.3,
        delay: 0.7,
      })

      gsap.from(".hero-image-wrapper", {
        y: 200,
        delay: 0.7,
        duration: 0.3,
      })

      gsap.from(".card", {
        x: -200,
        delay: 0.7,
        duration: 0.3,
      })

      gsap.to(".card", {
        autoAlpha: 1,
        delay: 0.7,
        duration: 0.5,
      })

      gsap.to(".description", {
        autoAlpha: 1,
        duration: 0.2,
        delay: 1.4,
      })

      gsap.to(".button", {
        autoAlpha: 1,
        duration: 0.2,
        delay: 1.6,
      })

      gsap.to(".title", {
        autoAlpha: 1,
        duration: 0.2,
      })

      tl.from(".line .word", 1.2, {
        y: -200,
        ease: "power4.out",
        delay: 0.5,
        opacity: 0,
        stagger: {
          amount: 0.4,
        },
      })
    }
  }, [])
  return (
    <S.Wrapper>
      <S.CustomContainer maxWidth={"xl"}>
        <Grid container spacing={{ xs: 2, sm: 3, md: 0 }}>
          <Grid item sm={7} xs={12}>
            <S.TextWrapper>
              {title && (
                <S.Title className="title animated-title">{title}</S.Title>
              )}
              {description && (
                <S.Paragraph className="description">{description}</S.Paragraph>
              )}
              {buttonLink && (
                <S.Button className="button" href={buttonLink.url}>
                  {buttonLink.title}
                </S.Button>
              )}
            </S.TextWrapper>
          </Grid>
          <Grid item sm={5} xs={12}>
            <S.ImageWrapper>
              <S.StyledBox>
                <S.ImageHero
                  className="hero-image-wrapper"
                  img={roundedImage}
                  alt="Hands"
                />
                <S.HandImage
                  className="hero-image-wrapper"
                  img={handImage}
                  alt="hand image"
                />
                <S.Card className="card">
                  <S.CardIcon />
                  <S.CardPolygon />
                  <S.CardText>EMPOWERING YOUR TEAM</S.CardText>
                </S.Card>
              </S.StyledBox>
            </S.ImageWrapper>
          </Grid>
        </Grid>
      </S.CustomContainer>
    </S.Wrapper>
  )
}

export default TestimonialHero
