import React from "react"
import * as S from "./our-services-hero.styles"
import CustomHero from "../CustomHero"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import WhatSetsApart from "../WhatSetsApart"
import CardsOurServices from "../CardsOurServices"

export const OurServicesHero = ({
  title,
  titleTwo,
  paragraph,
  buttonLink,
  buttonLinkTwo,
  image,
  cardText,
  dark,
  haveConfetti,
  secondTitle,
  description,
  bgImage,
  items,
  cardsTitle,
  cards,
}) => {
  return (
    <S.Wrapper>
      <CustomHero
        paragraph={paragraph}
        image={image}
        title={title}
        titleTwo={titleTwo}
        cardText={cardText}
        dark={dark}
        haveConfetti={haveConfetti}
        buttonLink={buttonLink}
      />
      {cards && (
        <CardsOurServices
          title={cardsTitle}
          cards={cards}
          buttonLink={buttonLinkTwo}
        />
      )}
      <WhatSetsApart
        title={secondTitle}
        description={description}
        bgImage={bgImage}
        items={items}
      />
    </S.Wrapper>
  )
}

export default OurServicesHero
