import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Section = styled(SectionWrapper)``

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 3rem;
  line-height: 4rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 2rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 0;
    font-size: ${({ theme }) => theme.typography.pxToRem(62)};
    line-height: ${({ theme }) => theme.typography.pxToRem(74)};
    max-width: 540px;
  }
`

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: space-between;
    flex-direction: row;
  }
`

export const LogosWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 60%;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: 5rem;
    width: 40%;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 30%;
  }
`

export const Logo = styled(CustomImage)`
  width: 100%;
  height: 30px;
  img {
    object-fit: contain !important;
    object-position: left;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 45px;
  }
`

export const CommentsWrapper = styled.div`
  margin-top: 3rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 6rem;
  }
`

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`

export const LoadMoreButton = styled(CustomButton)``
