import React from "react"
import * as S from "./testimonial-partners.styles"
import TrophyIcon from "../../assets/trophy.svg"
import TestimonialCard from "../../components/testimonial-card/testimonial-card.component"
import parse from "html-react-parser"
const TestimonialPartners = ({ title, topTitle, partners, note }) => {
  return (
    <S.Section id="partners">
      <S.Wrapper maxWidth="xl">
        <S.TopTitle>
          <TrophyIcon />
          {topTitle}
        </S.TopTitle>
        <S.Title variant="h2">{parse(title)}</S.Title>
        <S.CardsWrapper>
          {partners?.map((partner, index) => (
            <TestimonialCard
              {...partner}
              key={`testimonial-card-${index}`}
              first={index === 0}
              last={index === partners.length - 1}
            />
          ))}
        </S.CardsWrapper>
        {note && <S.Note>{parse(note)}</S.Note>}
      </S.Wrapper>
    </S.Section>
  )
}

export default TestimonialPartners
