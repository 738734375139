import styled from "@emotion/styled"
import { Container, Typography } from "@mui/material"
import EmporaMap from "../../assets/empora-map.svg"

export const Section = styled.section`
  padding: 4rem 0;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 3.25rem 0;
  }
  background: ${({ theme }) => theme.palette.secondary.main};
`

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`

export const MapWrapper = styled.div`
  position: relative;
  left: -1.5rem;
  display: flex;
  align-items: center;
  align-self: baseline;
  ${({ theme }) => theme.breakpoints.between("sm", "lg")} {
    align-self: unset;
    width: 50%;
    height: fit-content;
  }
`

export const MapPoints = styled(EmporaMap)`
  width: 100%;
  height: auto;
`

export const MapDot = styled.div`
  position: absolute;
  z-index: 2;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  /* ${({ theme }) => theme.breakpoints.between("sm", "md")} {
    width: 20px;
  } */
  width: 30px;
  height: 30px;
  div:not(:nth-of-type(1)) {
    position: absolute;
    border: 1px solid ${({ theme }) => theme.palette.tertiary.main};
    opacity: 1;
    border-radius: 50%;
    animation: dot-ripple 2s ease-in-out infinite;
  }
  div:nth-of-type(3) {
    animation-delay: -0.25s;
  }
  div:nth-of-type(1) {
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: relative;
    left: 8px;
    top: 8px;
  }
  @keyframes dot-ripple {
    0% {
      top: 14px;
      left: 14px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 14px;
      left: 14px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 14px;
      left: 14px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 28px;
      height: 28px;
      opacity: 0;
    }
  }
`

export const ItemsWrapper = styled.div`
  padding: 3.5rem 4rem 1.5rem 4rem;
  display: flex;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 0;
    padding-bottom: 0;
    width: 60%;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 0 6rem;
    width: 50%;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 75%;
  }
`

export const ItemTitle = styled(Typography)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(62)};
  line-height: 74px;
  color: ${({ theme }) => theme.palette.text.light};
  font-family: ${({ theme }) => theme.fonts.secondary};
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 5rem;
    line-height: 6rem;
  }
`

export const ItemDescription = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.palette.text.light};
  opacity: 0.6;
  p {
    margin: 0;
  }
`
