import React, { useState, useEffect } from "react"
import * as S from "./blogs-filter.styles"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import useDebounce from "../../hooks/useDebounce"
import { CustomCloseSearchIcon } from "./blogs-filter.styles"

const BlogsFilter = ({ setBlogsToShow, blogs, allBlogs }) => {
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchTerm = useDebounce(searchValue, 1000)
  const [openInput, setOpenInput] = useState(false)
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down("md"))

  const filterData = val => {
    if (!val) {
      setBlogsToShow(blogs)
      return
    }

    setBlogsToShow(
      allBlogs?.filter(blog => {
        if (blog?.title?.toLowerCase().includes(val.toLowerCase().trim())) {
          return blog
        } else {
          return blog?.postBuilder?.layouts?.some(({ content }) =>
            content?.toLowerCase().includes(val.toLowerCase().trim())
          )
        }
      })
    )
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      filterData(debouncedSearchTerm)
    } else {
      filterData(null)
    }
  }, [debouncedSearchTerm])

  return (
    <S.Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <S.Subtitle>Latest News</S.Subtitle>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <S.InputWrapper>
            <S.SearchInput
              className={openInput ? "openInput" : ""}
              fullWidth
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder="Search..."
              variant="standard"
            />
            {isMd ? (
              <S.CustomSearchIcon />
            ) : openInput ? (
              <S.CustomCloseSearchIcon onClick={() => setOpenInput(false)} />
            ) : (
              <S.CustomSearchIcon onClick={() => setOpenInput(true)} />
            )}
          </S.InputWrapper>
        </Grid>
        {/*<S.SelectGrid item xs={12} sm={12} md={4}>*/}
        {/*  /!*<S.SelectWrapper>*!/*/}
        {/*  /!*  <CustomTextField*!/*/}
        {/*  /!*    SelectProps={{*!/*/}
        {/*  /!*      IconComponent: S.ArrowIcon,*!/*/}
        {/*  /!*      displayEmpty: true,*!/*/}
        {/*  /!*      defaultValue: "",*!/*/}
        {/*  /!*    }}*!/*/}
        {/*  /!*    select*!/*/}
        {/*  /!*  >*!/*/}
        {/*  /!*    <MenuItem disabled value="">*!/*/}
        {/*  /!*      Filter*!/*/}
        {/*  /!*    </MenuItem>*!/*/}
        {/*  /!*    <MenuItem disabled value="">*!/*/}
        {/*  /!*      Filter*!/*/}
        {/*  /!*    </MenuItem>*!/*/}
        {/*  /!*    <MenuItem disabled value="">*!/*/}
        {/*  /!*      Filter*!/*/}
        {/*  /!*    </MenuItem>*!/*/}
        {/*  /!*  </CustomTextField>*!/*/}
        {/*  /!*</S.SelectWrapper>*!/*/}
        {/*</S.SelectGrid>*/}
      </Grid>
    </S.Wrapper>
  )
}

export default BlogsFilter
