import styled from "@emotion/styled"
import { Container, Typography } from "@mui/material"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  overflow: hidden;
  padding-top: 3.5rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 750px;
    padding-top: 5rem;
  }
`

export const Wrapper = styled(Container)`
  position: relative;
  height: 100%;
`

export const BgImage = styled(CustomBgImage)`
  position: absolute;
  width: 50%;
  height: 100%;
  left: -1.5rem;
  bottom: -1.5rem;
  display: none;
  ${({ theme }) => theme.breakpoints.up("md")} {
    /* left: 1.5rem; */
    display: block;
    bottom: -9rem;
  }
`

export const Title = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(62)};
  line-height: ${({ theme }) => theme.typography.pxToRem(74)};
  color: ${({ theme }) => theme.palette.text.light};
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 550px;
  }
`

export const TopTitle = styled(Typography)`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
  letter-spacing: 0.1em;
  padding-bottom: 1rem;
`
