import { Box } from "@mui/material"
import React from "react"
import * as S from "./rating-card.styles"
import StarIcon from "../../assets/star-2.svg"

const RatingCard = ({ comment, name, stars, company, via, avatarImage }) => {
  return (
    <Box>
      <S.CommentWrapper>
        <S.Comment>
          {comment}
          <S.PolygonWrapper>
            <S.PolygonShape />
            <S.PolygonShape2 className="polygon-2" />
          </S.PolygonWrapper>
        </S.Comment>
      </S.CommentWrapper>
      <S.BottomWrapper>
        {avatarImage && <S.AvatarImage img={avatarImage} alt={name} />}
        <Box>
          <S.BottomWrapper className="text">
            <S.Name>{name}</S.Name>
            <S.Name className="company">{company}</S.Name>
            {via && (
              <S.Via href={via.url} target="_blank">
                {via.title}
              </S.Via>
            )}
          </S.BottomWrapper>
          <S.StarsWrapper>
            {stars &&
              stars > 0 &&
              Array(+stars)
                .fill(0)
                .map((_, index) => <StarIcon key={`star-${index}`} />)}
          </S.StarsWrapper>
        </Box>
      </S.BottomWrapper>
    </Box>
  )
}

export default RatingCard
