import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const CustomSection = styled(SectionWrapper)`
  padding: 4.5rem 0;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 7.5rem 0;
    h2 {
      margin-bottom: 4.5rem;
    }
  }
  background-color: ${({ theme }) => theme.palette.secondary.main};
  h2 {
    font-size: ${({ theme }) => theme.typography.pxToRem(34)};
    line-height: 41px;
    text-align: center;
    color: ${({ theme }) => theme.palette.secondary.light};
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 650px) {
    .MuiGrid-item {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
`
export const MapWrapper = styled.div`
  max-width: 750px;
`
export const CustomContent = styled.div`
  max-width: 332px;
  margin: auto;
  color: ${({ theme }) => theme.palette.text.light};
  ul {
    padding-left: 24px;
  }
  ul,
  li,
  p {
    opacity: 0.7;
    margin-bottom: 0;
  }

  li {
    font-size: 9px;
    span {
      font-size: 0.875rem;
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    li {
      white-space: nowrap;
    }
  }

  &.coming-soon {
    margin-top: 2.5rem;

    ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }
  }
`
export const CustomTag = styled.h5`
  width: 100%;
  line-height: 36px;
  border-radius: 36px;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  background-color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.palette.text.light};

  &.light {
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: #222231;
  }
`
