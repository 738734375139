import React from "react"
import { Grid, Typography } from "@mui/material"
import * as S from "./map.styles"
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import { useTheme } from "@emotion/react"

const Map = ({ title, activeStates, comingSoonStates }) => {
  const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json"
  const active = activeStates.map(state => state.state)
  const theme = useTheme()
  const coming = comingSoonStates.map(state => state.state)

  return (
    <S.CustomSection contained maxWidth="xl">
      <h2>{title}</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7}>
          <S.MapWrapper>
            <ComposableMap projection="geoAlbersUsa">
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map(geo => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={
                        coming.includes(geo.properties.name)
                          ? theme.palette.primary.light
                          : active.includes(geo.properties.name)
                          ? theme.palette.primary.main
                          : "#222231"
                      }
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none" },
                        pressed: { outline: "none" },
                      }}
                      strokeWidth={1}
                      stroke={"#2F2F42"}
                    />
                  ))
                }
              </Geographies>
            </ComposableMap>
          </S.MapWrapper>
        </Grid>
        <Grid item xs={12} sm={5}>
          {activeStates && activeStates?.length > 0 && (
            <S.CustomContent>
              <S.CustomTag>Active States</S.CustomTag>
              <Typography>
                Empora is licensed and operating in these states:
              </Typography>
              <ul>
                {activeStates?.map(state => (
                  <li key={state?.state}>
                    <span>{state?.state}</span>
                  </li>
                ))}
              </ul>
            </S.CustomContent>
          )}
          {comingSoonStates && comingSoonStates?.length > 0 && (
            <S.CustomContent className="coming-soon">
              <S.CustomTag className="light">Coming Soon</S.CustomTag>
              <Typography>
                Empora is licensed in these states, but we are not fully
                operational in them. Ask your customer representative for more
                details.
              </Typography>
              <ul>
                {comingSoonStates?.map(state => (
                  <li key={state?.state}>
                    <span>{state?.state}</span>
                  </li>
                ))}
              </ul>
            </S.CustomContent>
          )}
        </Grid>
      </Grid>
    </S.CustomSection>
  )
}

export default Map
