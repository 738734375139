import React, { useEffect } from "react"
import Pagination from "@mui/material/Pagination"
import * as S from "./custom-pagination.styles"
import { Link } from "react-scroll"
import { isBrowser } from "../../utils/utils"

const CustomPagination = ({
  isOnePage,
  pageContext,
  handlePagination,
  scrollTo,
}) => {
  const { currentPage, numPages } = pageContext
  useEffect(() => {
    if (isBrowser) {
      const el = document.getElementById("featuredArticle")
      if (el) {
        if (currentPage > 1) {
          el.style.display = "none"
        } else {
          el.style.display = "block"
        }
      }
    }
  }, [currentPage])

  if (isOnePage) return null
  return (
    <div>
      <Pagination
        count={numPages}
        page={currentPage}
        onChange={handlePagination}
        hidePrevButton
        hideNextButton
        siblingCount={0}
        renderItem={item => (
          <Link to={scrollTo} spy={true} offset={-80}>
            <S.CustomPaginationItem
              // components={{ previous: ArrowLeft, next: ArrowRight }}
              {...item}
            />
          </Link>
        )}
      />
    </div>
  )
}

export default CustomPagination
