import styled from "@emotion/styled"
import { Container, Typography } from "@mui/material"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Section = styled(SectionWrapper)`
  padding-top: 3.75rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 5rem;
  }
`

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(62)};
  line-height: ${({ theme }) => theme.typography.pxToRem(74)};
  text-align: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 785px;
  }
`

export const TopTitle = styled(Typography)`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
  letter-spacing: 0.1em;
  padding-bottom: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-top: 2.5rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 4rem;
  }
`
export const Note = styled.div`
  margin-top: 1.25rem;
  max-width: 812px;
  align-self: flex-start;
  p {
    font-size: 0.875rem;
    line-height: 19px;
    margin: 0;
  }
`
