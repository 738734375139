import styled from "@emotion/styled"
import { Grid, TextField } from "@mui/material"
import * as SearchIcon from "../../assets/search.svg"
import CloseIcon from "@mui/icons-material/Close"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Typography from "@mui/material/Typography"

export const SearchInput = styled(TextField)`
  justify-content: center;

  input {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 21px;
    color: rgba(47, 47, 66, 1);
    font-family: ${({ theme }) => theme.fonts.secondary};
    //padding: 0.5em 2em 0.5em 1.5em;
    &::placeholder {
      color: rgba(47, 47, 66, 0.5);
    }
  }

  > div {
    &:before {
      border-bottom: 2px solid rgba(47, 47, 66, 0.2);
      left: 0;
      bottom: 0;
      content: "";
      position: absolute;
      right: 0;
      -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1)
        0ms;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      pointer-events: none;
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 0;
    transition: width 0.5s ease;
    &.openInput {
      transition: width 0.5s ease;
      width: 100%;
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    input {
      font-size: ${({ theme }) => theme.typography.pxToRem(24)};
      line-height: 31px;
      color: rgba(47, 47, 66, 1);
      font-family: ${({ theme }) => theme.fonts.secondary};
      padding: 0.5em 2em 0.5em 0em;
    }
  }
`

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 100%;
`

export const CustomSearchIcon = styled(SearchIcon)`
  position: absolute;
  color: black;
  width: 24px;
  height: 24px;
  top: 50%;
  right: 1%;
  transform: translate(-1%, -50%);
  &:hover {
    cursor: pointer;
  }
`

export const CustomCloseSearchIcon = styled(CloseIcon)`
  position: absolute;
  color: black;
  width: 24px;
  height: 24px;
  top: 50%;
  right: 1%;
  transform: translate(-1%, -50%);
  &:hover {
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 4rem;
  }
  padding-top: 2em;
`

export const ArrowIcon = styled(KeyboardArrowDownIcon)`
  fill: ${({ theme }) => theme.palette.secondary.main};
`

export const SelectWrapper = styled.div`
  min-width: 160px;
  margin-left: auto;
`

export const SelectGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

export const Subtitle = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(34)};
  line-height: ${({ theme }) => theme.typography.pxToRem(57)};
  color: ${({ theme }) => theme.palette.secondary.main};
`
