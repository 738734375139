import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"
import Polygon from "../../assets/polygon-2.svg"
import Polygon2 from "../../assets/polygon-3.svg"
import CustomLink from "../custom-link/custom-link.component"

export const CommentWrapper = styled.div`
  position: relative;
  z-index: 4;
  margin-bottom: 2.25rem;
  &:hover {
    > div::before {
      opacity: 1;
    }
    .polygon-2 {
      opacity: 1;
    }
  }
`

export const Comment = styled.div`
  background: white;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 21px;
  padding: 1.5rem;
  position: relative;
  z-index: 99;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 1.75rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  ::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    border: 1px solid transparent;
    background: #f0eae4;
    z-index: -2;
    transition: opacity 0.3s ease-in-out;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: destination-out;
  }
  ::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    border: 1px solid transparent;
    background: linear-gradient(121.52deg, #fc4a5a -7.61%, #0038ff 184.73%);
    z-index: -1;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: destination-out;
  }
`

export const BottomWrapper = styled.div`
  display: flex;
  &.text {
    gap: 0.25rem;
  }
`

export const PolygonWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  bottom: -42px;
  left: 63px;
  z-index: 2;
`

export const PolygonShape = styled(Polygon)`
  position: relative;
  top: -10px;
`

export const PolygonShape2 = styled(Polygon2)`
  position: relative;
  top: -10px;
  left: -37px;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
`

export const AvatarImage = styled(CustomImage)`
  overflow: hidden;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 0.75rem;
`

export const Name = styled(Typography)`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 0.875rem;
    line-height: 19px;
  }
  &.company {
    font-weight: 400;
  }
`

export const Via = styled.a`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.text.primary};
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 0.875rem;
    line-height: 19px;
  }
`

export const StarsWrapper = styled.div`
  display: flex;
  gap: 0.125rem;
  margin-top: 0.25rem;
`
