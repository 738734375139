import styled from "@emotion/styled"

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  //padding: 2em 0;
  margin-top: -2rem;
`

export const Wrapper = styled.div`
  padding: 4em 0 4em 0;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-bottom: 0;
  }
`

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  text-align: center;
`
