import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"

export const AchievementCard = styled.div`
  min-height: 220px;
  border: 2px solid rgba(249, 245, 242, 0.6);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: 250px;
    padding: 2rem;
  }
`

export const AchievementIcon = styled(CustomImage)`
  height: 30px;
  width: auto;
  img {
    object-fit: contain !important;
    object-position: left;
  }
`

export const AchievementTitle = styled(Typography)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(46)};
  line-height: 55px;
  color: ${({ theme }) => theme.palette.text.light};
  font-family: ${({ theme }) => theme.fonts.secondary};
`

export const AchievementDescription = styled(Typography)`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.palette.text.light};
`

export const AuthorCard = styled.div`
  background: linear-gradient(121.52deg, #fc4a5a -7.61%, #0038ff 184.73%);
  border-radius: 4px;
  padding: 1.5rem;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 1.5rem 2.5rem;
  }
`

export const IconTitle = styled(CustomImage)`
  height: 30px;
  img {
    object-fit: contain !important;
    object-position: center;
    ${({ theme }) => theme.breakpoints.up("sm")} {
      object-position: left;
    }
  }
`

export const AuthorPhrase = styled.div`
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.text.light};
  margin-top: 1rem;
  text-align: center;
  p {
    margin: 0;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    text-align: left;
  }
`

export const AvatarCard = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

export const AvatarImage = styled(CustomImage)`
  width: 74px;
  height: 74px;
  margin-bottom: 0.75rem;
`

export const AvatarName = styled(Typography)`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 20px;
  margin-bottom: 0.125rem;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.light};
`

export const AvatarDescription = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.light};
  opacity: 0.7;
  max-width: 96px;
  p {
    margin: 0;
  }
`
