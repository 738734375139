import { Grid } from "@mui/material"
import React, { useEffect } from "react"
import AchievementCards from "../../components/achievement-cards/achievement-cards.component"
import { isBrowser } from "../../utils/utils"
import * as S from "./our-achievements.styles"
import { useTheme } from "@emotion/react"
import { useMediaQuery } from "@mui/material"
import { ScrollToPlugin, ScrollTrigger, gsap } from "gsap/all"

const OurAchievements = ({ title, bgImage, topTitle, items }) => {
  const theme = useTheme()
  const upMd = useMediaQuery(theme.breakpoints.up("md"))
  useEffect(() => {
    if (isBrowser && upMd) {
      gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
      gsap.to(".scroll-animation", {
        yPercent: -100,
        ease: "none",
        scrollTrigger: {
          trigger: ".sectionContainer",
          start: "-80% top",
          end: "100% 500",
          // markers: true,
          scrub: 1,
          invalidateOnRefresh: true,
        },
      })
    }
  }, [upMd])
  return (
    <S.Section className="sectionContainer">
      <S.Wrapper maxWidth="xl">
        <S.BgImage img={bgImage} />
        <Grid container spacing={{ xs: 6, md: 8 }}>
          <Grid item xs={12} md={5} lg={6.5}>
            <S.TopTitle>{topTitle}</S.TopTitle>
            <S.Title variant="h2">{title}</S.Title>
          </Grid>
          <Grid item xs={12} md={7} lg={5.5} position="relative">
            {upMd && (
              <>
                {items.map((item, index) => {
                  return (
                    <AchievementCards
                      {...item}
                      key={`achievement-cards-${index}`}
                      className="scroll-animation"
                    />
                  )
                })}
              </>
            )}
            {!upMd && (
              <>
                {items.map((item, index) => (
                  <AchievementCards
                    {...item}
                    key={`achievement-cards-${index}`}
                    className="scroll-animation"
                  />
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default OurAchievements
