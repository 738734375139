import styled from "@emotion/styled"
import { Container, Typography } from "@mui/material"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import EIcon from "../../assets/e-logo.svg"

export const Section = styled(SectionWrapper)`
  overflow: hidden;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 2rem;
  }
`

export const Wrapper = styled(Container)`
  position: relative;
`

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(62)};
  line-height: ${({ theme }) => theme.typography.pxToRem(74)};
  margin-top: 1rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
`

export const Description = styled.div`
  font-size: ${({ theme }) => theme.typography.pxToRem(21)};
  line-height: ${({ theme }) => theme.typography.pxToRem(28)};
  margin-top: 2rem;
`

export const EmporaIcon = styled(EIcon)`
  position: absolute;
  bottom: -28%;
  right: -28%;
  path {
    fill: #f0eae4;
  }
`
