import React from "react"
import * as S from "./blog-card.styles"
import parse from "html-react-parser"
import PlusCircle from "../../assets/pluss-circle.svg"
import { useTheme } from "@emotion/react"
import { useMediaQuery } from "@mui/material"

const BlogCard = ({ title, slug, excerpt, featuredImage, date }) => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <S.Wrapper>
      <S.Link url={`/blog/${slug}`}>
        <S.ImageContainer>
          <S.Image img={featuredImage} />
          <S.ImageOverlay className="overlay">
            <PlusCircle />
          </S.ImageOverlay>
        </S.ImageContainer>
        <S.TextWrapper>
          <S.Date>{date}</S.Date>
          <S.Title>{title}</S.Title>
          {!isMd && parse(excerpt)}
        </S.TextWrapper>
      </S.Link>
    </S.Wrapper>
  )
}

export default BlogCard
