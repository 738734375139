import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"
import PlayIcon from "../../assets/play.svg"

export const Wrapper = styled.div`
  border-radius: 24px;
  padding: 2.5rem 1.25rem;
  position: relative;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 5.5rem 6rem;
  }
  z-index: 1;
  ::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 24px;
    background-image: linear-gradient(136.29deg, #28284a 19.66%, #40406b 95%);
    z-index: -1;
    transition: opacity 0.3s linear;
    opacity: 0;
  }
  &:hover,
  &.active {
    ::before {
      opacity: 1;
    }
    color: ${({ theme }) => theme.palette.text.light};
    .tag {
      border: 0.5px solid white;
    }
    .map-marker {
      path {
        fill: white;
      }
    }
  }
`

export const LeftWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const PlayButton = styled(PlayIcon)`
  width: 64px;
  height: auto;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
`

export const PlayWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  left: -1.25rem;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    left: -6rem;
  }
`

export const VideoImage = styled(CustomImage)`
  width: 100%;

  img {
    border-top-right-radius: 400px;
    border-bottom-right-radius: 400px;
  }
`

export const Name = styled(Typography)`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 28px;
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 0.125rem;
`

export const Title = styled(Typography)`
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 20px;
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.secondary};
  opacity: 0.7;
`

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(21)};
  line-height: 28px;
  color: inherit;
  margin-top: 1.25rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 1.5rem;
    line-height: 32px;
  }
`

export const FeaturesWrapper = styled.div`
  margin-top: 2.5rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 3.5rem;
  }
`

export const FeatureTitle = styled(Typography)`
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 32px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: inherit;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 2.75rem;
    line-height: 52px;
  }
`

export const FeatureDescription = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1rem;
  color: inherit;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 1.125rem;
    line-height: 20px;
  }
`

export const City = styled(Typography)`
  font-size: 1rem;
  line-height: 1rem;
  color: inherit;
  display: flex;
  gap: 0.5rem;
  font-style: italic;
  margin-top: 2.5rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  svg {
    opacity: 0.5;
    path {
      fill: ${({ theme }) => theme.palette.secondary.main};
    }
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-top: 3.5rem;
  }
`

export const TagsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-top: 1rem;
`

export const Tag = styled(Typography)`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 19px;
  color: inherit;
  padding: 0.375rem 0.875rem;
  border: 0.5px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 20px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 1rem;
    line-height: 21px;
  }
`
